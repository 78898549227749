.block{
    padding: 30px 0;
    border-bottom: 1px solid #DDDEE0;
}
.block h2{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 10px;
}
.image-item {
    display: flex;
}
.logo_wrapper{
    background: #FFFFFF;
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
}
.logo_wrapper img{
    width: 100%;
}
.iframe_block{
    /* text-align: center; */
}
.iframe_button{
    background: #F3F4F6;
    border-radius: 16px;
    padding: 15px 30px;
    border: 0;
    transition: 0.3s all ease-in;
}
.iframe_button:hover {
    background: #262628;
    color: #fff;
}
.iframe_code{
    padding: 20px 0 0 0;
}
.iframe_code textarea{
    width: 100%;
}
.list{
    list-style: none;
    /* padding: 20px 0 0 0; */
}
.list_item{
    border: 1px solid #DDDEE0;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.add_block{
    margin: 30px 0 0 0;
    background: #FFFFFF;
    box-shadow: 0px 8px 25px rgba(17, 17, 17, 0.1);
    border-radius: 16px;
}
.edit-btns-box{
    display: flex;
    justify-content: space-between;
    width: 130px;
}
.edit-btns-box .button_clickable {
    margin: 0;
}
