.bt_status{
    background: #FFA400;
    border-radius: 16px;
    border: 0;
    width: 100%;
    font-size: 15px;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.change_status_popup{
    width: 600px;
    padding: 50px 100px 30px;
    margin: 0 auto;
}
.change_status_popup button{
    margin-top: 15px;
}

/* Pop ups confirm alart */
.popup-content {
    position: relative;
    width: auto;
    padding: 85px 250px;
    border: none;
    background: transparent;
    border-radius: 16px;
  }

.b-popup_title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1px;
    color: #262628;
    font-size: 25px;
}

.b-popup_text {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.75px;
    color: #f30000;
}

.b-popup_btns {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.b-popup_btn {
    width: 200px;
    height: 60px;
    border-radius: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.17;
    letter-spacing: -0.02em;
    color: #262628;
    background: #F3F4F6;
    transition: 0.3s all ease-in-out;
}

.b-popup_btn:first-child {
    margin-right: 30px;
    color: #FFFFFF;
    background: #262628;
}