.news_list{
  list-style: none;
  padding: 30px 0 0 0;
}
.news_list li{
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 0 20px 0;
}
.news_list li p{
    padding: 30px 0 0 0;
    text-align: center;
    margin: 0;
}
.add_article{
  padding-top: 20px;
}
.add_article a {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    /* width: 294px;
    height: 60px;
    border: 1px solid #262628;
    border-radius: 16px;
    background: #262628;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    transition: 0.3s all ease-in;
  float: right; */
}
/* .add_article a:hover{
    background: #FFFFFF;
    color: #262628;
} */