.button_clickable_edit {
    position: relative;
    display: flex;
    align-items: center;
    width: 160px;
    height: 60px;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    background: #F3F4F6;
    border-radius: 16px;
    margin: 0 auto;
    transition: 0.3s all ease-in;
}
.button_clickable_edit:hover{
    background: #262628;
    border: 1px solid #262628;
}
.button_clickable_edit:hover button{
    color: #FFFFFF;
}
.button_clickable_edit svg path {
    transition: 0.3s all ease-in;
}
.button_clickable_edit:hover svg path {
    stroke: #fff;
}
.button_clickable_edit button {
    background: transparent;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    z-index: 0;
    transition: 0.3s all ease-in;
}
.button_clickable_edit svg {
    margin-right: 5px;
}
.grid_button{
    position: relative;
    height: 60px;
    border-radius: 16px;
    background: #F3F4F6;
}
.grid_button button{
    padding: 0 10px 0 43px;
}
.grid_button:hover{
    background: #262628;
}
.grid_button svg{
    position: relative;
    margin: 18px 0 0 14px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.grid_button svg path{
    transition: 0.3s all ease-in;
}
.grid_button:hover svg path{
    stroke: #FFFFFF;
    z-index: 1;
    padding: 0 20px 0 20px;
}
.grid_button_template a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #262628;
    text-decoration: none;
    transition: 0.3s all ease-in;
}
.grid_button_template:hover a{
    border-color: #262628;
    background: #262628;
    color: #FFFFFF;
}
.grid_button_template svg{
    margin: 0 5px 0 0;
}
.popup-content {
    margin: auto;
    width: 1200px;
}
.edit_content{
    height: calc(100vh - 100px);
    max-height: 800px;
}
.close{
    width: 47px;
    height: 47px;
    border: 1px solid #aaa!important;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 0;
    background: 50% 50% no-repeat #f3f4f6!important;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10000;
}

.edit_body{
    width: 95%;
}

.form_group .save_button{
    display: block;
    width: 260px;
    margin: 0 auto;
}

/* Pop ups confirm alart */
.popup-content {
    position: relative;
    width: auto;
    padding: 85px 250px;
    border: none;
    background: transparent;
    border-radius: 16px;
  }

.b-popup_title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1px;
    color: #262628;
    font-size: 25px;
}

.b-popup_text {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.75px;
    color: #f30000;
}

.b-popup_btns {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.b-popup_btn {
    width: 200px;
    height: 60px;
    border-radius: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.17;
    letter-spacing: -0.02em;
    color: #262628;
    background: #F3F4F6;
    transition: 0.3s all ease-in-out;
}

.b-popup_btn:first-child {
    margin-right: 30px;
    color: #FFFFFF;
    background: #262628;
}
