.bt_status{
    background: #FFA400;
    border-radius: 16px;
    border: 0;
    width: 100%;
    font-size: 15px;
    color: #fff;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.search_box {
    display: flex;
    justify-content: flex-end;
}
.search_text{

}
.search_text input{
    margin-right: 7px;
    padding: 14px 23px 13px;
    background: transparent;
    float: left;
    display: block;
    width: 316px;
    border: 1px solid #DDDEE0;
    border-radius: 12px;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    letter-spacing: -0.02em;
    color: #262628;;
}
.search_text input::placeholder{
    color: #99A0A3;
}
.search_text button{
    width: 50px;
    height: 50px;
    background: #262628;
    border-radius: 16px;
    border: 1px solid #262628;
    float: right;
    display: block;
}