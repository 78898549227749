.search_btn {
  margin-left: 10px;
  transition: 0.3s all ease-in;
}
.search_btn:hover{
  background: #FFFFFF;
}
.search_btn svg path{
  transition: 0.3s all ease-in;
}
.search_btn:hover svg path{
  stroke: #262628;
}

button:focus {
  outline: none;
}

.btn.focus, .btn:focus {
  box-shadow: none;
}

.back_button {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  background: #F3F4F6;
  border-radius: 16px;
  border: none;
}

.menu {
  position: fixed;
}

.admin-wrapper {
  padding-right: 30px;
}

.popup {

  &__title {
    padding: 50px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 1px;
    color: #262628;
  }

  &__inner {
    padding: 0 50px 50px;
    margin: 0 auto;
  }
}