.color_circule{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 10px;
}
.color_hex{
    display: inline-block;
    padding: 15px 0 0 0;
}
.colors_box h2{
    font-size: 18px;
}
.colors_list{
    padding: 10px 0 0 0;
    width: 60%;
}
.color_item{
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 15px;
}
.color_item p{
    color: #FFA400;
    text-align: center;
    margin: 0;
    padding: 15px 0 0 0;
}
.color_item .price{
    padding: 15px 0 0 0;
}
.color_item .button_clickable{
    margin: 0;
}
.add_color_block{
    background: #FFFFFF;
    box-shadow: 0px 8px 25px rgba(17, 17, 17, 0.1);
    border-radius: 16px;
    padding: 30px;
    margin-top: 30px;
    width: 60%;
}