html{
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/Inter-Regular.woff') format('woff'),
       url('fonts/Inter/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/Inter-Bold.woff') format('woff'),
       url('fonts/Inter/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/Inter-SemiBold.woff') format('woff'),
       url('fonts/Inter/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
}
body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
