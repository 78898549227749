@import '../vars-mixins.scss';

.navigation{
    height: 100vh;
}
.menu ul{
    padding: 38px 14px 55px 17px;

    @include r('xxl') {
        padding: 25px 14px 55px 17px;
    }
}
.menu li{
    list-style: none;
    margin-bottom: 5px;
}
.menu li a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
    height: 58px;
    padding: 0 15px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    letter-spacing: 0;
    color: #99A0A3;
    text-decoration: none;
    transition: 0.3s all ease-in;
}
.menu li a svg {
    padding: 0 0 0 10px;
    width: 35px;
    height: 30px;
    float: right;
}
.menu li a svg path{
    stroke: black;
}
.menu li a svg circle{
    stroke: black;
}
.menu li a:hover{
    background: #262628;
    color: #fff;
}
.menu li a:hover svg path, .menu li a:hover svg rect, .menu li a:hover svg circle{
    stroke: white;
}

.menu li a.active{
    background: #262628;
    color: #fff;
}
.menu li a.active svg path, .menu li a.active svg rect, .menu li a.active svg circle{
    stroke: white;
}
