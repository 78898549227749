
.register_block{
    width: 850px;
    margin: 100px auto;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    padding: 30px 0 0 0;
}
.register_block h1{
    text-align: center;
}