@import '../vars-mixins.scss';

.pages_list{
    list-style: none;
    width: 65%;
    padding: 50px 0 0 0;

    @include r('xxl') {
        padding: 40px 0 0 0;
    }
}
/* .pages_list li{
    margin-bottom: 15px;
} */
.pages_list li{
    height: 80px;
    margin-bottom: 15px;
    padding: 0 30px;
    border: 1px solid #DDDEE0;
    border-radius: 12px;
}
.pages_list .row{
    height: 100%;
}
.pages_list .row > div{
    display: flex;
    align-items: center;
    height: 100%;
}
.pages_number{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 122%;
    letter-spacing: 0.3px;
    color: #FFA400;
}
.pages_header{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 157%;
    letter-spacing: 0.3px;
}
.pages_edit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #DDDEE0;
    border-radius: 12px;
    background: none;
    transition: 0.3s all ease-in;
}
.pages_edit-btn:hover{
    background: #262628;
    border-color: #262628;
}
.pages_edit-btn svg path{
    transition: 0.3s all ease-in;
}
.pages_edit-btn:hover svg path{
    stroke: #FFFFFF;
}