.gallery_controls{
    padding: 20px 0 0 0;
}
.gallery_item{
    padding: 30px 0;
}
.gallery_image{
    padding: 10px;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    /* width: 150px; */
}
.gallery_item img{
    width: 100%;
    height: auto;
}
.gallery_item_controls{
    /* padding: 20px 0 0 0; */
}
.gallery_item_controls button{
    margin-bottom: 19px;
    width: 100%;
}