.template_list{
    list-style: none;
    margin-top: 30px;
}
.template_list li{
    border-bottom: 1px solid #000;
    padding: 20px;
}
.template_list li img{
    width: 100%;
}
.template_img img{
    width: 100%;
}
.modal_body{
    height: 600px;
}