@import '../vars-mixins.scss';

.header{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px!important;
    padding: 0 15px 0 15px;
    background: #FFFFFF;
    -webkit-box-shadow: 1px 6px 34px -16px rgba(0,0,0,0.47); 
    box-shadow: 1px 6px 34px -16px rgba(0,0,0,0.47);
    z-index: 100;

    @include r('xxl') {
        height: 70px!important;
    }
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 150px;
    }
    &__logo img,
    &__logo svg {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.logo{
    width: 200px;
}
.nav{
    float: right;
}
.nav li{}
.nav li a{
    display: block;
    margin-left: 20px;
    padding: 20px 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 112%;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #262628;
    transition: 0.3s all ease-in;
}