@import '../vars-mixins.scss';

.history_popup-content{
    width: 74%!important;
    margin: 90px auto!important;
    border-radius: 16px;
    overflow: hidden;
}
.orders_history_title{
    margin: 90px 0 73px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71%;
    text-align: center;
    letter-spacing: 1px;
    color: #262628;

    @include r('xxl') {
        font-size: 36px;
        margin: 60px 0;
    }
}
.orders_history_table{
    width: auto;
    margin: 0 auto 30px;
    border: 1px solid #DDDEE0;
}
.orders_history_table tr{
    display: flex;
    height: 70px;
}
.orders_history_table tr:not(:last-child){
    border-bottom: 1px solid #DDDEE0;
}
.orders_history_table th:not(:last-child),
.orders_history_table td:not(:last-child){
    border-right: 1px solid #DDDEE0;
}
.orders_history_table th,
.orders_history_table td{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 184px;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 122%;
    text-align: center;
    @include r('xxl') {
        width: 150px;
    }
}
.orders_history_table th{
    font-size: 18px;
    @include r('xxl') {
        font-size: 14px;
    }
}
.orders_history_table td{
    font-size: 14px;
    @include r('xxl') {
        font-size: 12px;
    }
}
.orders_history_column{
    width: 184px;
}
.orders_history_head_row{
    background: #F3F4F6;
}