@import 'vars-mixins.scss';

.app {
    padding-top: 100px;
    overflow: hidden;
    z-index: 0;

    @include r('xxl') {
        padding-top: 70px;
    }
}

.form_group {
    position: relative;
    padding: 20px 0;
}
.form_group label{
     display: block;
     padding-bottom: 10px;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
}
.form_group input[type="text"], .form_group input[type="number"], .form_group input[type="password"], .form_group textarea {
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    width: 100%;
    border-radius: 16px;
    padding: 15px;
}
.form_group textarea {
    min-height: 100px;
}
//Hide arrows in numbers
/* Chrome, Safari, Edge, Opera */
.form_group input[type="number"]::-webkit-outer-spin-button,
.form_group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.form_group input[type="number"] {
    -moz-appearance: textfield;
}
.form_error{
    bottom: 0;
    color: red;
    font-size: 12px;
}
.order_button{
    width: 360px;
    height: 90px;
    background: #FFA400;
    border-radius: 16px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    transition: 0.3s all ease-in;
}
.order_button:hover{
    background: #262628;
}
.save_button{
    background: #FFA400;
    border-radius: 16px;
    padding: 20px;
    border:0;
    color: #fff;
    width: 260px;
    transition: 0.3s all ease-in;
}
.save_button:hover {
    background: #262628;
}
.sidebar{
    position: relative;
    width: 262px;
    height: calc(100vh - 90px);
    float: left;
    background-color: #FFFFFF;
    z-index: 50;

    @include r('xxl') {
        min-height: calc(100vh - 70px);
    }
}
.content{
    min-height: calc(100vh - 100px);
    border-left: 1px solid #DDDEE0;
    margin-left: 262px;
    overflow: hidden;

    @include r('xxl') {
        min-height: calc(100vh - 70px);
    }
}
.content_top__search-box{
    display: flex;
    justify-content: flex-end;
}
.content_body{
    padding: 55px 0 0 55px;

    @include r('xxl') {
        padding: 30px 0 0 40px;
    }
}
.content_body h1{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 34px;
    letter-spacing: 1px;

    @include r('xxl') {
        font-size: 30px;
    }
}
.content_body h3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 1px;

    @include r('xxl') {
        font-size: 24px;
    }
}

.edit_content h1{
    text-align: center;
}

.content_top{
    padding: 0 30px 30px 0;
}

.content_top .col-6{
    display: flex;
    align-items: center;
}
.grid .rdt_TableHeadRow{
    /*border-left-width: 0;*/
}
.grid_top{
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 20px 30px 20px 59px;
    background: #FFFFFF;
    box-shadow: 0 8px 25px rgba(17, 17, 17, 0.1);
    border-radius: 16px;
    margin: 0 30px 35px 0;

    @include r('xxl') {
        padding: 15px 0 15px 27px;
    }
}
.grid_top__btn-box{
    display: flex;
    justify-content: flex-end;
    width: 264px;
    margin-left: auto;

    @include r('xxl') {
        width: 215px;
    }
}
.grid_top .row {
    align-items: center;
    width: 100%;
}
.grid_top .btn_black {
    width: 100%;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #262628;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    transition: 0.3s all ease-in;

    @include r('xxl') {
        height: 50px;
    }
}
.grid_top .btn_black:hover {
    background: #FFFFFF;
    color: #262628;
}
.grid_top_add-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 60px;
    margin-left: 20px;
    padding: 10px!important;
    border-radius: 16px!important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    transition: 0.3s all ease-in;
}
.grid_top_add-btn:hover{
    background: #262628;
    color: #FFFFFF;
}
.grid_top_add-btn svg{
    margin-right: 11px;
}
.grid_top_add-btn svg path{
    transition: 0.3s all ease-in;
}
.grid_top_add-btn:hover svg path{
    stroke: #FFFFFF;
}
.date_group{
    width: 150px;
    margin-right: 30px;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    height: 50px;

    @include r('xxl') {
        width: 125px;
        margin-right: 15px;
    }

    @include r('xl') {

    }
 }
.date_left{
    position: relative;
    margin-right: 45px;
    box-sizing: border-box;
    height: 100%;
}
.date_input{
    position: relative;
    display: flex;
    align-items: center;
    z-index: 99;
}
.date_input::before{
    display: block;
    margin-right: 10px;
    font-size: 18px;
    line-height: 122%;
    letter-spacing: 0;

    @include r('xxl') {
        font-size: 16px;
    }
}
.date_from::before{
    content: 'С';
}
.date_right{
    background: #F3F4F6;
    width: 50px;
    height: 100%;
    border-radius: 16px;
    border-left: 1px solid #DDDEE0;
    float: right;
    cursor: pointer;
}
.date_to::before{
    content: 'До';
}
.date_right svg{
    margin-left: 13px;
    margin-top: 11px;
}
.DayPickerInput{
    width: 100%;
}
.react-datepicker-wrapper{
    position: absolute;
    left: 0;
    top: 0;
}
.react-datepicker__input-container input{
    height: 48px;
    border: 0;
    width: 100%;
    outline: none;
    background: none;
    box-sizing: border-box;
    padding: 10px;

    @include r('xxl') {
        font-size: 10px;
    }
}
.btn{
    height: 45px;
    padding: 10px 60px;
    border: 0;
    border-radius: 12px;
    background: #F3F4F6;
    transition: 0.3s all ease;
}
.btn:hover {
    background: #262628;
    color: #fff;
}
.btn_black {
    background: #262628;
    color: #fff;
}
.btn_black:hover {
    background: #FFA400;
}
.btn-admin {
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #DDDEE0;
    color: #fff;
    background: #262628;
    transition: 0.3s all ease;
}
.btn-admin:hover {
    color: #262628;
    background: #fff;
}
.order-select-product{
    position: relative;
    width: 14%;
    margin-right: 15px;
    z-index: 100;

    @include r('xxl') {
        width: 135px;
        font-size: 10px;
    }
}
.order-select-status{
    position: relative;
    width: 15%;
    margin-right: 15px;
    z-index: 100;

    @include r('xxl') {
        width: 135px;
        font-size: 10px;
    }
}

/**** GRID ****/
.grid{
    position: relative;
    border-bottom: 1px solid #DDDEE0;
    border-left: 1px solid #DDDEE0;
}
// .rdt_Table{
//     overflow-x: initial;
// }
// .rdt_TableBody{
//     max-height: calc(100vh - 500px)!important;

//     @include r('xxl') {
//         max-height: calc(100vh - 410px)!important;
//     }
// }
// .rdt_TableHead{
//     position: sticky!important;
//     position: -webkit-sticky!important;
//     top: 0!important;
//     left: 0!important;
//     z-index: 2;
// }
.rdt_TableHead div {
    text-align: center;
}
.rdt_TableCol{
    background: #F3F4F6;
    padding: 10px 0;
    font-weight: lighter;
}
.rdt_TableCol_Sortable{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #262628;
}
.rdt_TableCol_Sortable span{
    position: absolute;
    right: 8px;
}
.rdt_TableCell div{
    white-space: initial!important;
    text-align: center;
}
.rdt_Pagination{
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: none!important;
    z-index: 3;
}
.grid_control_wrapper{
    width: 100%;
}
.grid_text_big{
    font-size: 14px;
    line-height: 157%;
    letter-spacing: 0.3px;
}
/*.grid_edit{
    display: block;
    width: 100%;
    background: #F3F4F6;
    border-radius: 16px;
    padding: 10px 20px;
}
.grid_edit svg{
    float: right;
    width: 15px;
}*/
.grid_edit{
    border: 1px solid #DDDEE0;
    background: #F3F4F6;
    border-radius: 16px;
    width: 45px;
    height: 45px;
}
.grid_template-btn{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border: none;
    background: transparent;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #262628;
    z-index: 0;
    transition: 0.3s all ease-in;
}
.grid_template-btn:hover{
    color: #FFFFFF;
    text-decoration: none;
}
.data_success{
    color: #37C734;
}
.data_error{
    color: #FA0000;
}
.cell_image{
    height: 89px;
    width: 100px;
    padding: 0 13px;
    border: 1px solid #DDDEE0;
    border-radius: 16px;
    overflow: hidden;
}
.cell_image img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.status_text{
    margin-bottom: 10px;
    text-align: center;
}
.form_group textarea{
    width: 100%;
}
.color_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.color{
    width: 20px;
    height: 20px;
    margin: 2px;
    border-radius: 50%;
    border: 1px solid #262628;
    display: inline-block;
}
.button_clickable {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 12px;
    background: #FFFFFF;
    transition: 0.3s all ease-in;
}
.button_clickable:hover{
    background: #262628;
    border-color: #262628;
}
.button_clickable:hover button{
    color: #FFFFFF;
}
.button_clickable svg path {
    transition: 0.3s all ease-in;
}
.button_clickable:hover svg path {
    stroke: #fff;
}
.button_clickable button {
    background: transparent;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}
.button_clickable svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.popup-overlay {
    background: rgba(0,0,0,.5);
}
.popup-content {
    margin: auto;
    width: calc(100vw - 200px);
    max-width: 1400px;
    border: 1px solid #d7d7d7;
    border-radius: 16px;
    background: #fff;
}
.reset_btn{
    width: 46px;
    height: 46px;
    border: 1px solid #d7d7d7!important;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 0;
    background: 50% 50% no-repeat #f3f4f6;
    transition: 0.3s all ease-in;
}
.reset_btn:hover{
    background: #262628;
}
.reset_btn svg path{
    transition: 0.3s all ease-in;
}
.reset_btn:hover svg path{
    stroke: #FFFFFF;
}

/*Scrollbar for tables*/
/*.scrollbar-container{
    padding-top: 80px;
}*/

.scrollbar-container > div:first-child {
    overflow-x: initial!important;
    overflow-y: initial!important;
}

.rdt_TableBody{
    overflow: initial!important;
}

.ps__rail-y{
    z-index: 2;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
    opacity: 0.6;
}

//Selects under table
.css-yk16xz-control, .css-1pahdxg-control {
    min-height: 48px !important;
    border-radius: 16px !important;
}

.css-1pahdxg-control {
    border-color: #ccc !important;
    box-shadow: none !important;
}

//Hide admin on modal
.hide-mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    &__text-box {
        max-width: 370px;
    }

    &__title {
        margin-bottom: 20px;
        font-size: 24px;
        text-align: center;
    }

    &__text {
        display: inline-block;
        font-size: 16px;
        text-align: center;
    }
}