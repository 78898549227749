$breakpoints: (
  's': 543px,
  'sm':  767px,
  'md':  991px,
  'lg': 1199px,
  'xl': 1400px,
  'xxl': 1900px,
  'xxxl': 1920px,
) !default;

@mixin r($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}