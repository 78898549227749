.news_list{
  list-style: none;
  padding: 30px 0 0 0;
}
.news_list li{
    border: 1px solid #DDDEE0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 0 20px 0;
}
.news_list li p{
    padding: 30px 0 0 0;
    text-align: center;
    margin: 0;
}